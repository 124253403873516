<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>

          <!-- lang tabs -->
          <a-row align="middle" class="flex-baseline sm-mb">
            <a-col :span="3" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Название: </a-col>
            <a-col :span="15" class="sm-mb">
              <a-input
                v-model="form['title_' + getKey]"
                name="linkName"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="3" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="document_files_array.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  multiple
                  @change="handleDocumentInput"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />

                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>

              <InputFileItem
                v-for="(doc, index) in newsDocs"
                :key="index"
                :href="form.file"
                :file="{ ...doc, name: doc.file.split('/').pop() }"
                class="mt-2"
                link
                @delete="deleteInputFile(doc)"
              />
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="3" class="form_title"> Ссылка: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.url"
                type="url"
                name="linkUrl"
                placeholder="Введите ссылку (Обязательно http:// или https:// )"
                :class="{
                  isInvalidUrl:
                    ($v.form.url.$dirty && !$v.form.url.required) ||
                    ($v.form.url.$dirty && !$v.form.url.url)
                }"
              />
              <small
                v-if="$v.form.url.$dirty && !$v.form.url.required"
                class="error-message"
                >Введите название ссылки</small
              >
              <small v-else-if="!$v.form.url.url" class="error-message"
                >Введите корректную ссылку в которой есть http:// или
                https://</small
              >
            </a-col>
          </a-row>

          <!-- active toggle -->
          <a-row style="margin-bottom: 25px">
            <a-col :span="3" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="3" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button
                  style="margin-left: 10px"
                  type="primary"
                  @click="
                    submit(form.id ? (saveback = false) : (saveback = true))
                  "
                >
                  <a-icon type="check" />
                  {{ form.id ? $t("Save") : $t("Add") }}
                </a-button>
                <a-button
                  v-if="form.id"
                  style="margin-left: 10px"
                  type="primary"
                  @click="submit((saveback = true))"
                >
                  <a-icon type="check" />
                  {{ $t("SaveAndExit") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>

        <!--<a-tab-pane key="documents">
          <span slot="tab"> <a-icon type="project" />Документы</span>
        </a-tab-pane>-->
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import InputFileItem from "@/components/utils/input-file-item"

import { url } from "vuelidate/lib/validators"
export default {
  components: { InputFileItem },
  data() {
    return {
      form: {
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: "",
        url: "",
        budget_information: null,
        file: null
      },
      fileList: [],
      langTab: "oz",
      document_files_array: []
    }
  },
  validations: {
    form: {
      url: {
        url
      }
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods: {
    newsDocs() {
      return this.form && this.form.filter((item) => item.file)
    },
    async deleteDocumentFileFromState(e) {
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      if (this.form && this.form.id) {
        const id = this.form.id
        await this.$api.patch(
          `/admin/budget/year-information-attachment/${id}/delete/file/`
        )
      }
    },
    handleDocumentInput(e) {
      // console.log(e.target.files)
      this.file_List = e.target.files
      if (e.target.files && e.target.files !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
      }
      console.log("array", this.document_files_array)
      this.$refs.documentRef.value = null
    },
    async fetchData() {
      if (!this.$route.params.id) return
      let id = this.$route.params.id
      const { data } = await this.$api.get(
        `/admin/budget/year-information-attachment/${id}/detail/`
      )
      this.form = data
      if (this.form && this.form.file) {
        this.document_files_array.push({
          binary_file: {
            file: this.form.file,
            name: this.form.file.split("/")[
              this.form.file.split("/").length - 1
            ]
          }
        })
        // console.log(this.document_files_array)
      }
    },
    async submit(saveback) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      let f = new FormData()
      this.form = {
        ...this.form,
        budget_information: this.$route.params.docTheme_id
      }
      Object.keys(this.form).forEach((key) => {
        key !== "file" && f.append(key, this.form[key])
      })
      const createOrUpdate = {
        url:
          this.form && this.form.id
            ? "/admin/budget/year-information-attachment/" +
              this.form.id +
              "/update/"
            : "/admin/budget/year-information-attachment/create/",
        method: this.form && this.form.id ? "patch" : "post"
      }
      if (
        this.document_files_array &&
        this.document_files_array[0] &&
        this.document_files_array[0].uid
      ) {
        this.document_files_array.forEach((item) => {
          item &&
            item.binary_file &&
            !item.binary_file.id &&
            f.append("file", item.binary_file, item.binary_file.name)
        })
      }
      try {
        let res = await this.$api[createOrUpdate.method](createOrUpdate.url, f)
        if ((res && res.status === 201) || res.status === 200) {
          this.$message.success(
            `Успешно ${this.form.id ? "обновлена" : "создана"}!`
          )
        }
        if (saveback) {
          await this.$router.push({ name: "attachment" })
        }
      } catch (e) {
        this.$message.error(
          `Ошибка при ${this.form.id ? "обновлении" : "создании"}!`
        )
        console.error(e)
      }
    }
  }
}
</script>

<style>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
.isInvalidUrl {
  border: 1px solid red;
  box-shadow: 0 0 3px red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
